// Core variables and mixins
@import "bootstrap/functions";
@import "_variables_theme";
@import "_variables";
@import "_mixins";
@import "_mixins_theme";

//Reset and dependencies
//@import "_fonts";

// Reset and dependencies
@import "_bootstrap";
@import "font-awesome/fontawesome-all";
@import "_fonts";

// Theme
@import "_custom";
@import "elements/navs";
@import "elements/covers";
@import "elements/lists";
@import "elements/main";
@import "elements/fancybox";
@import "elements/fullcalendar";
@import "elements/cookiebar"; 

// TEXT CONTENT DEFINITION
.content {
@import "elements/content";
}


// PRELOADER CLOSING
body {
	&:after, &:before {
		animation: showpage 1s;
		display:none;
	}
}
@keyframes showpage {
	0% {
		opacity:1;
		display:block;
	}
	98% {
		opacity:0;
		display:block;
	}
	100% {
		opacity:0;
		display:none;
	}
}
