
.navbar_main {
	font-size:0.8em;
	transition: opacity .5s;
	padding-top:10px;
	//font-family: $font-family-sans-serif;
	background: none;
	background-image:url(/source/dist/images/strip_over.png);
	background-position: bottom center;
	background-size: 100% 100%;
	padding-bottom:20px;
	&.bg-light {
		//background-color:rgba($white,.5) !important;
		background-color: transparent !important;
		background-image:url(/source/dist/images/strip_over.png);
		&:hover {
			//background-color:rgba($white,.8) !important;
		}
	}
	.navbar-toggler {
		border:none;
		.navbar-toggler-icon {
			color: $primary;
		}
	}
	.dropdown-menu {
		font-family: $font-family-sans-serif;
		font-size:0.9em;
	}
	.nav-link:after {
		display:none;
	}
	.nav-link {
		text-transform:uppercase;
		font-size:0.8em;
		letter-spacing: 0.125em;
		text-shadow: 0 0 3px rgba($white,.7);
	}
	.navbar-brand {
		width:120px;
		img {
			width:115px;
			position:absolute;
			top:5px;
			left:25px;
		}
	}
	.justify-content-end {
		.nav-link {
			color:$navbar-light-hover-color;
		}
	}
	.navbar-social {
		min-width:100px;
		.nav-link {
			text-transform:uppercase;
			font-size:1.3em;
			line-height:1em;

			span {
				display:none;
			}
		}
	}

	@include media-breakpoint-down(md) {
		.navbar-collapse {
			height: 100vh;
			padding-top: 30px;
			box-sizing: border-box;
			padding-bottom:50px;
			overflow:scroll;
		}
		.navbar-social {
			padding-top:20px;
			font-size:1em;
			.nav-link span {
				display:inline;
				font-size:0.8em;
			}
		}
		.dropdown-menu {
			background: none;
			border:none;
			border-radius:0px;
		}

	}

}

.navbar_bottom {
	height:51px;
	font-size:0.9em;
	margin-top:-51px;
	&.bg-primary {
		background-color:rgba($primary,.7) !important;
	}
	font-family: $font-family-serif;
	.nav-link {
		cursor: pointer;
	}
	.navbar-brand {
		font-size:1.1em;
	}
	@include media-breakpoint-down(md) {
		z-index:300;
		.collapse {
			margin-top:4px;
			margin-left:-15px;
			margin-right:-15px;
			background-color: rgba($primary,.9);
			padding-top:10px;
			padding-bottom:10px;
		}
	}
}

.navbar_bottom.navbar_bottom_prog {
	@include media-breakpoint-down(md) {
		z-index:300;
		.collapse {
			margin-top:4px;
			margin-left:0px;
			margin-right:0px;
			background: none;
		}
		.nav-link {
			font-size:0.8;
			padding-left:5px;
			padding-right:5px;
		}
	}
}


.tt-page {
	.navbar_bottom.bg-primary {
		background-color:rgba($primary-tt,.7) !important;
	}
	.navbar_main .nav-link.active {
		color:$primary-tt !important;
	}
}

.navbar_bottom.sticky-top {
	//z-index:2000;
	top: 60px;
	background-color: rgba($primary,.9) !important;
}


.breadcrumb {
	background: none;
	padding: 0;
	font-size: 0.8em;
	padding-bottom: 5px;
	border-bottom: dashed 1px #ccc;
}

.tab_menu {
	padding-bottom: 20px;
	border-bottom:dashed 1px #ccc;
	font-size:0.7em;
	text-transform: uppercase;
	letter-spacing: 0.125em;
	.nav-link {
		font-family: $font-family-serif;

	}
	.nav-link.active {
	 background:none;
	 border:solid 1px $primary;
	 color:$primary;
	 margin-top:-1px;

 }

 @include media-breakpoint-down(md) {
	 width:100%;
	 .nav-item {
 		width:100%;
 	}
	 .nav-link {
 		width:100%;
 	}
 }
}

.tt-page {
	.tab_menu {
		.nav-link {
			color:$primary-tt;
		}
		.nav-link.active {
			background:none;
			border:solid 1px $primary-tt;
			color:$primary-tt;
		}
	}
}

.navbar_footer {
	font-size:0.8em;
	font-family: $font-family-sans-serif;

	@include media-breakpoint-down(sm) {
		.nav-item {
			width:100%;
		}
	}

}

.navbar_calendar {
	#calendar {
		width:100%;
		position: absolute;
		top:-20px;
		left:0px;
	}
		.fc-toolbar {
			display:block;
			position:absolute;
			left:25px;
			top:80px;
			color:$white;
			a, a:hover {
				color:$white;
			}
			z-index:300;
		}
		.fc-view-container, .fc-view {
			width:100%;
			border:none;
			background: none;



		}
		.fc-list-empty, .fc-list-empty-wrap2, .fc-list-empty-wrap1 {
			background: none;
			background-color: none;
			color:$white;
		}
		.moon_icon_holder {
			height:10px;
			width:100px;
			position:relative;
			display:inline-block;
			.moon_icon {
				height:110px;
				position: absolute;
				top:-40px;
				left:-20px;
			}
		}

		.gaia-moon {
			opacity: 1;
		}
		.text-muted {
			color:$white !important;
		}
		.fc-scroller {
			width:100%;
			border:none;
			background: none;

		}
		.fc-list-table {
			width:auto;
			min-width:100%;

			display:block;
			border:none;
			background: none;




			tbody {
				display:inline-block;
				width:100vw;
				max-width:100vw;
				padding-left:30px;
				padding-right:30px;
				padding-top:35px;
				white-space:nowrap;
				overflow: auto;
				overflow-y: scroll !important;
				overflow-x: visible !important;
					padding-bottom:30px;
					box-sizing: border-box;
				@include media-breakpoint-up(lg) {
					text-align: center;

				}
			}
			a {
				color:$white;
			}
			.fc-event-dot {
				display:none;
			}
			.fc-widget-content:nth-child(2) {
				display:none;
			}
			.fc-widget-content:last-child {
				padding-left:0px;
			}
			tr {

				display:inline-block;
				color:$white;
				background: none;
				border:none;
				&:hover {
					background: none;
					td, tr {
						background: none;
					}
				}
			}
			td {
				display:inline;
				background: none;
				border:none;
				&:hover {
					background: none;
				}
			}
		}
}
