.teacher_item {
	position: relative;
	display:block;
	margin-bottom:20px;
	.teacher_item_overlay {
		position: absolute;
		top:0;
		left:0;
		width:100%;
		height:0;
		padding-bottom:100%;
		background-color: rgba($primary,.7);
		border-radius:50%;
		transition: background-color .5s;
		&:hover {
			background-color: rgba($primary,.3);
			h5, h4 {
				color:rgba($white,.3);
			}
		}
		h5, h4 {
			position: absolute;
			top:60%;
			left:50%;
			width:90%;
			color:$white;
			transform: translate(-50%,-50%);
			font-size:1.7em;
			text-align: center;
			transition: color .5s;
			small {
				font-size:0.6em;
			}
		}
	}
	img {
		width:100%;
	}

}

.press_cards, .event_cards, .blog_cards, .gallery_cards, .instagram_cards {
	.card {
		border:none;
		background: none;
		border-radius: 0px;
		a, a:hover {
			text-decoration: none;
		}
	}
	.polaroid {
		box-shadow: 0 0 5px 0 rgba($black,.6);
		background-color: $white;
		background-image: url(/source/dist/images/polaroid_bg.jpg);
		background-size: 100% 100%;
	}
	.card-body {
		min-height:80px;
		background: none;
		z-index:1;
		padding-top:15px;
	}
	.card-img {

	}
	.card-category {
		font-size:.7em;
		letter-spacing: 0.125em;
		text-shadow: 0 0 3px $black;
		color:$white;
	}
	.rounded-circle {
		max-width: 30px;
		width:30px;
		display:inline;
		margin-right:10px;
		//margin-left:10px;
		//box-shadow: 0 0 5px 0 $black;
	}
	.card-img-holder {
		padding:3%;
		display: block;
		position: relative;
		&:after {
			position: absolute;
			top:3%;
			left:3%;
			height:94%;
			width:94%;
			display: block;
			content:'';
			background-image: url(/source/dist/images/polaroid_over.png);
			background-size: 100% 100%;
			background-position: center center;
			z-index:50;
		}

	}
	.card-author {
		//position: absolute;
		//top:16px;
		//right:3%;
		display:block;
		color:$white;
		//text-shadow: 0 0 3px #000;
		//z-index:100;
		//font-family: $font-family-sans-serif;
		float:left;
	}
	.card-title {
		font-size:1em;
	}
	.card-text {
		font-weight:300;
		line-height:1.1em;
		text-align: justify;
	}
	.card-header {
		z-index:100;
		position: absolute;
		top:0px;
		width:100%;
		min-width: 100%;
		border:none;
		background: none;
		font-family: $font-family-sans-serif;

		color:$black;
		.card-date {
			margin-top:4vw;
		}
		.card-category {
			text-transform:uppercase;
		}

		.badge {
			font-weight: 300;
			box-shadow: 0 0 5px 0 $black;

		}
	}
	.card-header {
		text-shadow: 0 0 3px $black;
		h4 {
			font-size:1em;
			font-family: $font-family-serif;
			color:$primary;
			font-style: normal;
		}
	}
	.card-footer {
		background: none;
		font-size:0.9em;
		font-family: $font-family-sans-serif;
		font-style: italic;
		h4 {
			font-size:1.2em;

		}
		a, a:hover {
			text-decoration: none;

		}
	}

}


.event_cards {
	.card {
.card-header {
	padding:3%;
}
		.card-header .card-text {
			text-align: left;
			padding:15px;
			background-color: rgba($primary,.9);
			height:auto;
			color:$white;
			line-height:1em;
			display:block;
			width:100%;
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#998675+0,998675+100&0.9+0,0+100 */
background: -moz-linear-gradient(top, rgba(153,134,117,0.9) 0%, rgba(153,134,117,0) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(153,134,117,0.9) 0%,rgba(153,134,117,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(153,134,117,0.9) 0%,rgba(153,134,117,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6998675', endColorstr='#00998675',GradientType=0 ); /* IE6-9 */
			a {
				color:$white;
			}
			h4 {
				color:$white;
			}
		}

		.card-footer {
			background:none;
			font-family: $font-family-sans-serif;
		}


	}
}


.tt-page {
	.event_cards  {
		.polaroid .card-body {
			color: $primary-tt;
		}
		.card-title {
			color: $primary-tt;
		}
		a, a:hover {
			color:$primary-tt;
		}
		.card-footer {
			a, a:hover {
				color:$primary-tt;
			}
			.text-primary {
				color:$primary-tt !important;
			}
		}
	}
}

.event_cards {
	.card-body {
		min-height:130px;
	}
}
.press_cards {
	.card-body {
		min-height:100px;
	}
}

.gallery_cards {
	.card {
		position: relative;
		.polaroid, .card-footer {
			z-index:100;
		}
		&:after {
			z-index:1;
			position: absolute;
			top: 0px;
			left:0px;
			display:block;
			content:'';
			width:100%;
			height:90%;
			transform:rotate(3deg);
			box-shadow: 0 0 5px 0 rgba($black,.6);
			background-color: $white;
			background-image: url(/source/dist/images/polaroid_bg.jpg);
			background-size: 100% 100%;
		}
	}
}

.past_cards {
	.card {
		position: relative;
		.polaroid, .card-footer {
			z-index:100;
		}
		&:after {
			z-index:1;
			position: absolute;
			top: 0px;
			left:0px;
			display:block;
			content:'';
			width:100%;
			height:90%;
			transform:rotate(3deg);
			box-shadow: 0 0 5px 0 rgba($black,.6);
			background-color: $white;
			background-image: url(/source/dist/images/polaroid_bg.jpg);
			background-size: 100% 100%;
		}
	}
	.card-body small {
		color:#999;
	}
}

.program_cards  {
	.polaroid {
		box-shadow: 0 0 5px 0 rgba($black,.6);
		background-color: $white;
		background-image: url(/source/dist/images/polaroid_bg.jpg);
		background-size: 100% 100%;
		border-radius: 4px;
	}
	.card {
		border:none;

		.card-body {
			position:absolute;
			bottom:0px;
			background-color: rgba($primary,.9);
			color:$white;
			margin-left:10px;
			margin-right:10px;
			overflow:hidden;
			height:58px;
			transition:height .5s;

			a {
				color:$white;
			}
		}

		.card-title {
			font-size:0.7em;
			padding-top: 5px;
			padding-bottom:20px;
			letter-spacing: 0.125em;
			text-transform: uppercase;
			text-align: center;
			width:100%;
			border-bottom:solid 1px $white;

		}
		.card-text {
			font-family:$font-family-sans-serif;
			font-weight:300;
			font-size:0.85em;
			text-align: justify;
			line-height:1em;
			margin-top:10px;
		}
		.card-header {
			position: absolute;
			top:0px;
			border:none;
			background: 0px;
			width:100%;
		}


a:hover {
	text-decoration: none;
}

		&:hover {
			.card-body {

			  height:90%;
			}
		}
	}
	.card-footer {
		background: none;
		font-size:0.8em;
		font-family: $font-family-sans-serif;
		h4 {
			font-size:1.2em;

		}
		a, a:hover {
			text-decoration: none;

		}
	}
}

.tt-page {
	.teacher_item_overlay {
		background-color: rgba($primary,.7) !important;
		&:hover {
			background-color: rgba($primary-tt,.3) !important;
		}
	}
	.program_cards  {
		.polaroid .card-body {
			background-color: rgba($primary-tt,.7);
		}
		.card-footer {
			a, a:hover {
				color:$primary-tt;
			}
			.text-primary {
				color:$primary-tt !important;
			}
		}
	}



}

.instagram_cards {
	.card-img-holder {
		position: relative;
	}
	.card-infox {
		margin:3%;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#998675+0,998675+100&0.9+0,0+100 */
background: -moz-linear-gradient(top, rgba(153,134,117,0.9) 0%, rgba(153,134,117,0) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(153,134,117,0.9) 0%,rgba(153,134,117,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(153,134,117,0.9) 0%,rgba(153,134,117,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6998675', endColorstr='#00998675',GradientType=0 ); /* IE6-9 */
		height:auto;
		width:94%;
		max-height: 150px;
		overflow: hidden;
		color:$white;
		display:none;
		position:absolute;
		top:0px;
		padding:10px;
		z-index:200;

		a {
			color:$white;
		}
		font-size:0.8em;
		font-family:$font-family-sans-serif;
	}
	.card {
		position:relative;
		&:hover {
			.card-infox {
				display:block;
			}
		}
	}
	.card-footer {
		.card-text {
			display:block;
		}
	}

}


.testimonial_cards {
	h5 {
		font-family: $font-family-sans-serif;
		font-style: italic;
		font-weight: 300;
		position: relative;
		display:block;
		width:100%;
		border-top:dotted 1px $primary;
		padding-top:15px;
		&:before {
			position: absolute;
			content:'';
			display:block;
			right:102%;
			top:-40px;
			font-weight:400;
			font-size:6em;
			color:$primary;
			background-image: url(/source/dist/images/quote.png);
			background-position: center center;
			background-repeat: no-repeat;
			background-size: contain;
			width:90px;
			height:90px;

		}
	}
}
