
.jumbotron {
	background-color: transparent;
	padding-left:0px;
	padding-right: 0px;
}
.banner_card {
	max-height:120vh;
	min-height:70vh;
	border:none;
	border-radius: 0;
	overflow:hidden;
	position: relative;
	.card-img {
		top:50%;
		left:50%;
		transform: translate(-50%,-50%);
		z-index:1;
		position: absolute;
		@media screen and (max-aspect-ratio: 1920/1200) {
			height: 120%;
			width:auto !important;
	}
	@media screen and (min-aspect-ratio: 1920/1200) {
			width: 110%;
			height:auto;
	}
	}
	.card-img-overlay {
		z-index:100;
		background-color: rgba($primary,.5);
		position: relative;
		min-height: 70vh;
		padding-bottom:30px;

	}

	.jumbotron {
		max-width:800px;
		.lead {
			color:$white !important;
		}
	}
	&.text-center .jumbotron, .text-center .jumbotron {
		margin:0 auto;
	}
}

.gold-top {
  border-top: dotted 2px $primary;
}
.gold-bottom {
  border-bottom: dotted 2px $primary;
}

.tt-page {
	.card-img-overlay {
		background-color: rgba($primary-tt,.5) !important;
	}
  .content .lead {
  		color:$primary-tt !important;
  }
  .content h3{
  		color:$primary-tt !important;
  }


	.gold-top {
	  border-top: dotted 2px $primary-tt;
	}
	.gold-bottom {
	  border-bottom: dotted 2px $primary-tt;
	}



}

.wrinkled {
	width:100%;
	position: relative;
	background-position: top center;
	background-repeat: no-repeat;
	background-size: 100%;
	background-image: url(/source/dist/images/wrinkled_white.jpg);
}


.modal-special {
	.modal-header {
		background-color: $primary;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		color:$white;
		&.modal-larger {
			height:300px;
		}
	}

}

.blog_gallery {
	.img-thumbnail {
		box-shadow: 0 0 10px 0 rgba($black,.6);
	}
}


.table_prices {
	font-family: $font-family-sans-serif;
	.text-light {
		color:#999 !important ;
	}
	thead th {
		font-size:0.6em;
		text-transform: uppercase;
		letter-spacing: 0.125em;
	}
}

.accordion_menu {
	.card, .card * {
		border-radius: 0;
		background: none;
		border:none;
		padding-left:0px;
		padding-right:0px;
	}
	.card {
		li {
			padding-left:10px;
			margin-left:20px;
		}
	}
	button {
		cursor: pointer;
	}
	.card {
		border-top: dashed 1px #ccc;
	}
	&.tt-page {
		.btn-link {
			color:$primary-tt;
		}
	}
	.card-header {
		button:hover {
			text-decoration: none;
		}
		.text-more {
			color:#ccc;
			display:none;
			font-size:0.8em;
		}
		&:hover .text-more {
			color:#ccc;
			display:inline;
		}
	}
}
img.w-100 {
	z-index:1;
}
#testimonial_carousel {
	height:150px;
	z-index:300;
	.carousel-inner {
		height:60vh;
		.carousel-item {
			height:60vh;

		}
		.carousel-caption {
			padding-top:80px;
			text-align:left;
			bottom:auto;
			top:20px;
		}
		.carousel-quote {}
	}
}

.contact-holder {
	height:100vh;
	width:100%;
	position: relative;
	background-position: center center;
	background-size: cover;

	#map {
		transition: opacity 2s, height .5s ;
		position: absolute;
		bottom:0px;
		height:40vh;
		width:100%;
		left:0px;
		opacity:0.3;
		&:hover  {
			height:70vh;
		}

	}
	&:hover #map {
		opacity:0.9;
		@include media-breakpoint-down(md) {
			opacity:0.9;
		}
	}

}

.training_icons {
	img {
		max-width:100%;
	}
}

.video-polaroid {
		box-shadow: 0 0 5px 0 rgba($black,.6);
		background-color: $white;
		background-image: url(/source/dist/images/polaroid_bg.jpg);
		background-size: 100% 100%;
		border-radius: 2px;

}

.calendar_page {

 td {font-family: $font-family-sans-serif;}

.fc-toolbar h2 {
	font-size:1em;
}
.fc-basicWeek-view {
	.fc-time, .fc-title, .event-description {
		display:block;
	}

}

@include media-breakpoint-down(md) {
	.fc-month-view .fc-time {
		display:none;
	}
}

}


.simple_table {
	@include media-breakpoint-down(sm) {
		td,th {
			display:block;
			width:100%;
			font-size:0.8em;
		}
		thead {
			display:none;
		}
	}
}
.simple_table_s {
	@include media-breakpoint-down(sm) {
		td,th {
			font-size:0.8em;
		}
	}
}


#mantraModal {
	.modal-header {
		position: relative;
		.btn-primary {
			position: absolute;
			top:50%;
			left:50%;
			transform: translate(-50%,-50%);
			z-index:100;
		}
	}

}
