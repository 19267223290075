
p, input, textarea, label {
 font-family: $font-family-sans-serif;
 font-weight:300;
 text-align: justify;
}
.lead, h1, h2, h3, h4 {
	font-family: $font-family-serif;
  font-weight:400;
}
.lead {
		//font-style: italic;
		color:$primary;
    font-size: 1.20rem;
}
h3{
		color:$primary;
		font-size:1.3em;
}

ul {
  font-family: $font-family-sans-serif;
  font-weight:300;
}

table {
  td, th {
    border-top:dashed 1px #ccc;
  }
  td {
    font-family: $font-family-sans-serif;
    font-weight:300;
  }
}
