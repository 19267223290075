#cover_carousel {
	&.half_carousel {
		.carousel-item {
			height: 80vh;
		}
	}
	h1 {
		//font-style: italic;
		letter-spacing: 0.0625em;

		@include media-breakpoint-down(md) {
			font-size:2.5em;
			&.display-4 {
				font-size:2em;
			}
		}

	}
	h4 {
		//font-style: italic;
		letter-spacing: 0.3em;
		padding-bottom:20px;
		@include media-breakpoint-down(md) {
			font-size:1em;
		}
	}


	.carousel-item {
		overflow:hidden;
	  height: 100vh;
	  min-height: 400px;
	  background: no-repeat center center scroll;
	  -webkit-background-size: cover;
	  -moz-background-size: cover;
	  -o-background-size: cover;
	  background-size: cover;
		.carousel-caption {
	.container {
		//-webkit-filter: drop-shadow( -0px -0px 5px rgba($black,.6));
		//filter: drop-shadow( -0px -0px 5px rgba($black,.6));
		text-shadow: 0 0 5px rgba($black,.6);
	}
			position:absolute;
			right: 0;
bottom: 0;
left: 0;
			width:100%;
			height:100%;
			.container {
				position: relative;
				height:100%;
				.row {
					height:100%;
					position: relative;
					.col-12 {
						position: absolute;
						top:50%;
						left:0px;
						transform: translate(0,-50%);
						text-align: center;
					}

				}
			}

			img {
						width:100%;
						max-width:850px;
			}
		}
		img {
			position:absolute;
			top:50%;
			left:50%;
			transform: translate(-50%,-50%);

			@media screen and (max-aspect-ratio: 1920/1200) {
				height: 100%;
				width:auto !important;
		}
		@media screen and (min-aspect-ratio: 1920/1200) {
				width: 100%;
				height:auto;
		}
		}
		&:after {
			position: absolute;
			top:0px;
			left:0px;
			display: block;
			content:'';
			width:100%;
			height:100%;
			//background-color: rgba($primary,.5);
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			//background-image: url(/source/dist/images/cover_overlay.png);

		}
	}
	.carousel-indicators {
		bottom:60px;
	}

}
