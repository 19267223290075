#cookie-bar {
    background:$primary;
    height:auto;
    line-height:24px;
    color:$white;
    text-align:center;
    padding:5px 5px 5px 2px;
    &.fixed {
        position:fixed; top:0; left:0; width:100%;
    }
    &.fixed.bottom {
        bottom:0; top:auto;
    }
    p {
        margin:0; padding:0;
    }
    a {
        color:$white;
    }
 .cb-enable {
    background: none;
    border:solid 1px $white;
    margin-left:15px;
    padding-left:20px;
    padding-right:20px; 
    }

 .cb-disable {
    display:none;
    }

 .cb-policy {
    display:none;
    }

}
